import React from 'react'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'

const CalendarPage = ({
  data: {
    site: {
      siteMetadata: { title, calendarUrl },
    },
  },
}) => (
  <Layout>
    <section className="section">
      <Helmet title={`Tags | ${title}`} />
      <div className="container content">
        <div className="columns">
          <div
            className="column is-12"
            style={{ marginBottom: '6rem' }}
          >
            <h1 className="title is-size-2 is-bold-light">Events and Classes</h1>
            <iframe src={calendarUrl}
              style={{ border: 0, width: "100%", height: "80vh", frameborder: 0, scrolling: "no" }}>
            </iframe>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default CalendarPage

export const calendarPageQuery = graphql`
  query CalendarQuery {
    site {
      siteMetadata {
        title
        calendarUrl
      }
    }
  }
`

